import React from 'react';
import {useState,useCallback,useContext,useEffect} from 'react';
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import Schema from '../../schema.json';
import {ContextStore} from '../../reducer.js';

function pmt(rate_per_period, number_of_payments, present_value, future_value, type){
	if(rate_per_period !== 0.0){
			var q = Math.pow(1 + rate_per_period, number_of_payments);
			return -(rate_per_period * (future_value + (q * present_value))) / ((-1 + q) * (1 + rate_per_period * (type)));

	} else if(number_of_payments !== 0.0){
			return -(future_value + present_value) / number_of_payments;
	}

	return 0;
}

const percent = Schema.Parameter.percent;
function Form3() {
	const [cost,setCost] = useState(Schema.Step3.cost);
	const {procedureCostAvg,procedureCost,monthSet,formDispatch} =  useContext(ContextStore);	
	const renderRow = monthSet.map( (item,index) => {
			return(
				<tr key={index} >
					<td>{item.month} months at {percent*100}%*</td>
					<td>${item.monthlyPayment}</td>
					<td>${(procedureCostAvg+procedureCost).toFixed(2)}</td>
					<td>${item.diff}</td>
				</tr>
			);
		}	
	);

	useEffect(()=>{
		const newMonth = Schema.Parameter.monthSet.map( (month) => {
			const monthlyPayment = parseFloat((-pmt(percent/12,month,cost,0,0)).toFixed(2));
			const revenueGenerated = parseFloat((procedureCost+procedureCostAvg).toFixed(2)); 								
			const diff = parseFloat((revenueGenerated - monthlyPayment).toFixed(2));
			return{
				month:month,
				monthlyPayment:monthlyPayment,
				diff:diff
			};
		})
		formDispatch({type:"UPDATE_MONTH",payload:newMonth});
	},[cost, formDispatch, procedureCost, procedureCostAvg])
	return (
		<Table striped bordered responsive>
			<thead>
				<tr>
					<th className = "table-head"  colSpan="3">
						<Form.Label>
							{Schema.Step3.name}										
						</Form.Label>
					</th>
					<th colSpan="1">				
						<Form.Control 
							type="number" 
							name={Schema.Step3.name} 
							value={cost} 
							onChange={
								useCallback((e)=>{
									const val = parseFloat(e.target.value);
									if(val<0 || isNaN(val))
										return;
									setCost(val)
									
								},[])
							}
						/>
					</th>
				</tr>
				<tr>
					<th></th>
					<th>Monthly Payment</th>
					<th>Revenue Generated</th>
					<th>Difference in Payment</th>
				</tr>
			</thead>
			<tbody>
				{renderRow}				
			</tbody>
		</Table>
	);
}

export default Form3;
