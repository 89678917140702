import React from 'react';
import {ContextStore,formReducer} from './reducer.js';
import Form1 from './component/Forms/Form1';
import Form2 from './component/Forms/Form2';
import Form3 from './component/Forms/Form3';
import Form4 from './component/Forms/Form4';
import Schema from './schema.json';
import {Container} from 'react-bootstrap'
import './App.css';
const formInit = {
  procedureCostAvg:0,
  procedureCost:0,
  monthSet:Schema.Parameter.monthSet.map(month=>{
      return{
        month:month,
        monthlyPayment:0,
        diff:0,
      }
    }
  )
}

function App() {
  const [formState, formDispatch] = React.useReducer(formReducer, formInit);
  return (
    <ContextStore.Provider 
      value={{
        procedureCostAvg:formState.procedureCostAvg,
        procedureCost:formState.procedureCost,
        monthSet:formState.monthSet,
        formDispatch,
      }}
    >    
      <Container className ="form-table">
        <Form1/>
        <Form2/>  
        <Form3/>
        <Form4/>
      </Container> 
    </ContextStore.Provider> 
  );
}

export default App;
