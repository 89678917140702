import React from 'react';
import Schema from '../../schema.json';
import Table from 'react-bootstrap/Table'
import useForm from './useForm';

function Form2() {
	const {total,renderRow} = useForm("UPDATE_COST",Schema.Step2);
	return (
		<Table striped bordered responsive>
			<thead >
				<tr>
					<th className = "table-head" colSpan="4">Sleep and Medical Billing</th>
				</tr>
				<tr>
					<th>Procedure Billed to Medical</th>
					<th>Number per Month</th>
					<th>Average Revenue per Procedure</th>
					<th>Total Revenue</th>
				</tr>
			</thead>
			<tbody>				
				{renderRow}
				<tr></tr>
				<tr className="table-total">
					<td colSpan="2">Monthly revenue generated</td>
					<td colSpan="2">${total}</td>
				</tr>
			</tbody>
		</Table>
	);
}

export default Form2;
