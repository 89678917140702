import React from 'react';
import {useContext} from 'react';
import Table from 'react-bootstrap/Table'
import {ContextStore} from '../../reducer.js';

function Form4() {
	const {procedureCostAvg,procedureCost,monthSet} =  useContext(ContextStore); 
	const tableHead =  monthSet.map( (item,index) => <th key={index}>Revenue at {item.month} months</th>);
	const renderRow =  monthSet.map( (item,index) => <td key={index}>${(item.diff*12).toFixed(2)}</td>);
	
	return (
		<Table striped bordered responsive>
			<thead>		
				<tr>
					<th>Yearly Revenue Generated</th>
					{tableHead}
				</tr>
			</thead>
			<tbody>
				<tr className="result-table-row">
					<td>${(procedureCostAvg+procedureCost)*12}</td>
					{renderRow}
				</tr>
				<tr className="result-table-hint">
					<td></td>
					<td colSpan="3">*After monthly payment applied</td>
				</tr> 		
			</tbody>
		</Table>
	);
}

export default Form4;
