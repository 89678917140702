import {createContext} from 'react';

const ContextStore = createContext()

function formReducer(state, action) {
  switch(action.type) {
    case 'UPDATE_AVG_COST':
      return { ...state, procedureCostAvg: action.value };
    case 'UPDATE_COST':
      return { ...state, procedureCost: action.value };
    case 'UPDATE_MONTH':{
      return { ...state, monthSet: action.payload };
    }   
    default:
      return state;
  }
}

export {ContextStore,formReducer};