import React from 'react';
import {useState,useCallback,useContext} from 'react';
import Form from 'react-bootstrap/Form';
import {ContextStore} from '../../reducer.js';

const TableRow = ({item,onChange}) => {		
    const [data,setData] = useState(item);	
    const _onChange = (e)=>{
        const val = parseInt(e.target.value);
        if(val<0 || isNaN(val))
            return;
        const newItem = {...item,month:val,totalRevenue: data.revenue*val};
        setData(newItem);
        onChange(newItem);						
    };
    return(
        <tr>
            <td>{data.name}</td>
            <td>
                <Form.Control 
                    type="number" 
                    name={data.name} 
                    value={data.month} 
                    onChange={
                        useCallback(_onChange,[data.revenue, item, onChange])
                    }
                />
            </td>
            <td>${data.revenue}</td>
            <td>${data.totalRevenue}</td>			
        </tr>
    );
}

function useForm(action,rows) {
	const {formDispatch} =  useContext(ContextStore); 
	const [total,setTotal] = useState(0);
	const [form,setForm] = useState(rows);
    const renderRow =  rows.map((item,index) => 
        <TableRow key={index} index={index} item={item} 
            onChange={
                (newItem)=>{
                    const newForm = form;
                    newForm[index] = newItem;
                    const total = newForm.reduce( (accum,item) => item.totalRevenue+accum ,0);						
                    setTotal(total);
                    setForm(newForm);
                    formDispatch({type:action,value:total});
                }
            }
        ></TableRow>
	);
	return {total,renderRow};
}

export default useForm;